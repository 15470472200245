import React from "react";
import firebase from "../Firestore";
import { css } from "glamor";
import { CSSTransition } from "react-transition-group";
import uuidv4 from "uuid/v4";

import ContentText from "./ContentText";
import ContentImage from "./ContentImage";
import ContentAudio from "./ContentAudio";
import ContentLink from "./ContentLink";
import ContentFile from "./ContentFile";
import ActionBar from "./ActionBar";

import TextArea from "../components/TextArea";
import Icons from "../components/Icons";
import Title from "../components/Title";

import { colors } from "../styles";
import { isArrayEqual } from "../helpers";
import "../assets/css/animations.css";

class MobileEditor extends React.Component {
  state = {
    view: "text",
    title: this.props.title ? this.props.title : "",
    text: this.props.text,
    tags: this.props.tags ? this.props.tags : [],
    color: this.props.color ? this.props.color : "",
    scrolled: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.title !== this.props.title) {
      this.setState({ title: this.props.title });
    }
    if (prevProps.text !== this.props.text) {
      this.setState({ text: this.props.text });
    }
  }

  handleScroll = () => {
    if (window.scrollY > 100) {
      this.setState({ scrolled: true });
    } else if (window.scrollY === 0) {
      this.setState({ scrolled: false });
    }
  };

  componentWillMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  updateTags = tag => {
    let tags;
    if (this.state.tags.map(t => t.id).includes(tag.id)) {
      tags = this.state.tags.filter(t => t.id !== tag.id);
    } else {
      tags = [...this.state.tags, { id: uuidv4(), name: tag.name }];
    }
    this.setState({ tags });
  };

  updateColor = color => {
    this.setState({ color });
  };

  updateItem = id => {
    const { title, text, color, tags } = this.state;
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    const itemRef = db
      .collection("items")
      .doc(id)
      .set(
        {
          title,
          text,
          color,
          tags
        },
        { merge: true }
      );
  };

  deleteItem = id => {
    const that = this;
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    db.collection("items")
      .doc(id)
      .delete()
      .then(function() {
        that.props.closeEditor();
      })
      .catch(function(error) {
        console.error("Error removing document: ", error);
      });
  };

  checkForChanges = () => {
    if (this.props.title !== this.state.title) {
      return false;
    }
    if (this.props.text !== this.state.text) {
      return false;
    }
    if (this.props.color !== this.state.color) {
      return false;
    }
    if (!isArrayEqual(this.state.tags, this.props.tags)) {
      return false;
    }
    return true;
  };

  render() {
    const { view, tags, color } = this.state;
    const { closeEditor, selected } = this.props;
    const icons = [
      {
        id: 1,
        name: "fas fa-font",
        active: view === "text",
        action: () => this.setState({ view: "text" })
      },
      {
        id: 2,
        name: "far fa-image",
        active: view === "image",
        action: () => this.setState({ view: "image" })
      },
      {
        id: 3,
        name: "fas fa-music",
        active: view === "music",
        action: () => this.setState({ view: "music" })
      },
      {
        id: 4,
        name: "fas fa-link",
        active: view === "link",
        action: () => this.setState({ view: "link" })
      },
      {
        id: 5,
        name: "fas fa-file",
        active: view === "file",
        action: () => this.setState({ view: "file" })
      }
    ];
    let content;
    switch (view) {
      case "text":
        content = (
          <ContentText
            value={this.state.text}
            handleChange={e => this.setState({ text: e.target.value })}
            height={this.props.height - 184}
          />
        );
        break;
      case "image":
        content = (
          <ContentImage id={this.props.id} images={this.props.images} />
        );
        break;
      case "music":
        content = (
          <ContentAudio id={this.props.id} audioFiles={this.props.audioFiles} />
        );
        break;
      case "link":
        content = <ContentLink id={this.props.id} links={this.props.links} />;
        break;
      case "file":
        content = <ContentFile id={this.props.id} files={this.props.files} />;
        break;
      default:
        break;
    }
    return (
      <div
        {...css({
          float: "left",
          width: "100%",
          padding: 24,
          paddingBottom: 72,
          boxSizing: "border-box"
        })}
      >
        <CSSTransition
          in={this.state.scrolled}
          timeout={300}
          classNames="header"
          unmountOnExit
        >
          <div
            {...css({
              position: "fixed",
              left: 0,
              top: 0,
              width: "100%",
              height: 48,
              borderBottom: "1px solid #E2E2E2",
              boxSizing: "border-box",
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 10,
              zIndex: 1,
              background: "white"
            })}
          >
            <Icons icons={icons} />
          </div>
        </CSSTransition>

        <CSSTransition
          in={!this.state.scrolled}
          timeout={500}
          classNames="title"
          unmountOnExit
        >
          <div>
            <Title
              title={this.state.title}
              placeholder="Enter a title..."
              handleChange={e => this.setState({ title: e.target.value })}
            />
            <div
              {...css({
                float: "left",
                width: "100%",
                marginTop: 6,
                marginBottom: 24
              })}
            >
              <Icons icons={icons} />
            </div>
          </div>
        </CSSTransition>

        <div
          {...css({
            float: "left",
            width: "100%",
            marginTop: this.state.scrolled ? 72 : 0
          })}
        >
          {content}
        </div>
        <ActionBar
          id={this.props.id}
          tags={tags}
          color={color}
          selected={selected}
          closeEditor={closeEditor}
          updateColor={this.updateColor}
          updateTags={this.updateTags}
          updateItem={() => this.updateItem(this.props.id)}
          deleteItem={() => this.deleteItem(this.props.id)}
          disabled={this.checkForChanges()}
          allTags={this.props.allTags}
        />
      </div>
    );
  }
}

export default MobileEditor;
