import React from "react";
import { css } from "glamor";
import firebase from "../Firestore";

import Upload from "../components/Upload";

import { colors, listItem } from "../styles";

class ContentLink extends React.Component {
  handleLink = (link, callback) => {
    const links = [...this.props.links, { id: link + +new Date(), url: link }];
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    const itemRef = db
      .collection("items")
      .doc(this.props.id)
      .set(
        {
          links
        },
        { merge: true }
      );
    callback && callback();
  };
  render() {
    const { links = [] } = this.props;
    return (
      <div
        {...css({
          float: "left",
          width: "100%",
          height: "100%",
          overflowY: "scroll"
        })}
      >
        <ul {...css({ margin: 0, padding: 0, listStyle: "none" })}>
          {links.map(link => (
            <Link key={link.id} url={link.url} />
          ))}
        </ul>
        <Upload handleLink={this.handleLink} />
      </div>
    );
  }
}

export default ContentLink;

class Link extends React.Component {
  render() {
    const { url } = this.props;
    return <li {...css(listItem)}>{url}</li>;
  }
}
