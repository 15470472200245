import React from "react";
import { css } from "glamor";
import {
  DragSource,
  beginDrag,
  connectDragSource,
  isDragging
} from "react-dnd";
import { types } from "../dropTypes";

class Color extends React.Component {
  render() {
    const { connectDragSource, isDragging, is } = this.props;
    const colorOne = "#FFFFFF";
    const colorTwo = is;

    return connectDragSource(
      <div
        {...css({
          float: "left",
          width: 25,
          height: 25,
          background: `radial-gradient(
            ${colorOne},
            ${colorTwo}
          )`,
          borderRadius: 5,
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)",
          cursor: "pointer",
          opacity: isDragging && 0.5
        })}
      />
    );
  }
}

const colorSource = {
  beginDrag(props) {
    return { type: "color", is: props.is };
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

export default DragSource(types[0], colorSource, collect)(Color);
