import React from "react";
import firebase from "./Firestore";
import { CSSTransition } from "react-transition-group";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { css, select as $, media } from "glamor";
import produce from "immer";
import Dropzone from "react-dropzone";

import Items from "./Items";
import Editor from "./Editor";

import Mobile from "./Mobile";
import Desktop from "./Desktop";

import Color from "./components/Color";
import Tag from "./components/Tag";

import Input from "./components/Input";

import Icons from "./components/Icons";
import TextArea from "./components/TextArea";

import { colors, float, title, itemHeader } from "./styles";
import { countElementsOfArray } from "./helpers";

class App extends React.Component {
  state = {
    items: [],
    selected: "",
    height: window.innerHeight,
    width: window.innerWidth
  };

  updateDimensions = () => {
    this.setState({ height: window.innerHeight, width: window.innerWidth });
  };

  componentWillMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    const { uid } = this.props;
    const that = this;
    db.collection("items")
      .where("status", "==", "PUBLISHED")
      .where("uid", "==", uid)
      .onSnapshot(function(querySnapshot) {
        var items = [];
        querySnapshot.forEach(function(doc) {
          const id = doc.id;
          const data = doc.data();
          items.push({ id, ...data });
        });
        that.setState({ items });
      });
  }

  signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(function() {
        // Sign-out successful.
      })
      .catch(function(error) {
        // An error happened.
      });
  };

  getTags = (items = []) => {
    const allTags = [].concat(...items.map(item => item.tags));
    const counted = countElementsOfArray(allTags.map(t => t.name))
      .sort((a, b) => b.count - a.count)
      .slice(0, 5);
    const tags = allTags.reduce((y, x) => {
      if (!y.some(z => z.name === x.name)) y.push(x);
      return y;
    }, []);
    const mostUsed = tags.filter(t =>
      counted.map(c => c.name).includes(t.name)
    );

    return { mostUsed, tags };
  };

  render() {
    const selectedArray = this.state.items.filter(
      item => item.id === this.state.selected
    );
    const selected = selectedArray.length > 0 ? selectedArray[0] : {};
    const allTags = this.getTags(this.state.items);
    return (
      <div>
        <div
          {...css(
            media("(max-width: 800px)", {
              display: "none"
            })
          )}
        >
          <Desktop items={this.state.items} height={this.state.height} />
        </div>
        <div
          {...css(
            float,
            {
              boxSizing: "border-box",
              fontFamily: "Rubik"
            },
            media("(min-width: 801px)", {
              display: "none"
            })
          )}
        >
          <Mobile
            uid={this.props.uid}
            id={selected.id}
            title={selected.title}
            text={selected.text}
            images={selected.images}
            music={selected.audioFiles}
            links={selected.links}
            files={selected.files}
            tags={selected.tags}
            color={selected.color}
            items={this.state.items}
            selected={this.state.selected}
            selectItem={id => this.setState({ selected: id })}
            height={this.state.height}
            allTags={allTags}
          />
        </div>
      </div>
    );
  }
}

export default App;
