import React from "react";
import { css } from "glamor";

import { colors } from "../styles";

function Icons({ icons = [] }) {
  return (
    <div
      {...css({
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 18px",
        textAlign: "left"
      })}
    >
      {icons.map(icon => {
        return (
          <i
            {...css({
              fontSize: 24,
              color: icon.active ? colors.focus : "#474747"
            })}
            className={icon.name}
            onClick={icon.action}
            key={icon.id}
          />
        );
      })}
    </div>
  );
}

export default Icons;
