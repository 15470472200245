import React from "react";
import { css, select as $ } from "glamor";
import firebase from "../Firestore";

import Upload from "../components/Upload";

import { colors, listItem } from "../styles";

class ContentFile extends React.Component {
  handleUploadedFile = file => {
    const files = [
      ...this.props.files,
      {
        id: file.name + +new Date(),
        name: file.name,
        url: file.downloadURL,
        ref: file.ref
      }
    ];

    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    const itemRef = db
      .collection("items")
      .doc(this.props.id)
      .set(
        {
          files
        },
        { merge: true }
      );
  };

  deleteUploadedFile = file => {
    const storageRef = firebase.storage().ref();
    var fileRef = storageRef.child(`${file.ref}`);
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });

    const audioFiles = this.props.audioFiles.filter(
      audio => audio.id !== file.id
    );
    const itemRef = db
      .collection("items")
      .doc(this.props.id)
      .set(
        {
          audioFiles
        },
        { merge: true }
      );

    // Delete the file
    fileRef
      .delete()
      .then(function() {
        // File deleted successfully
        console.log("gone!");
      })
      .catch(function(error) {
        // Uh-oh, an error occurred!
      });
  };

  render() {
    const { files = [] } = this.props;
    return (
      <div
        {...css({
          float: "left",
          width: "100%",
          height: "100%",
          overflowY: "scroll"
        })}
      >
        <ul {...css({ margin: 0, padding: 0, listStyle: "none" })}>
          {files.map(audio => (
            <Audio key={audio.id} name={audio.name} />
          ))}
        </ul>
        <Upload
          accept=".pdf"
          handleUploadedFile={file => this.handleUploadedFile(file)}
        />
      </div>
    );
  }
}

export default ContentFile;

class Audio extends React.Component {
  render() {
    const { name } = this.props;
    return <li {...css(listItem)}>{name}</li>;
  }
}
