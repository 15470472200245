import React from "react";
import { css, select as $ } from "glamor";
import firebase from "../Firestore";

import Upload from "../components/Upload";

import { colors, listItem } from "../styles";

class ContentAudio extends React.Component {
  state = {
    selectedTrack: null
  };

  componentDidUpdate(prevProps, prevState) {
    const that = this;
    if (
      prevState.selectedTrack !== this.state.selectedTrack &&
      this.state.selectedTrack
    ) {
      this.player.pause();
      const storageRef = firebase.storage().ref();
      var fileRef = storageRef.child(`${this.state.selectedTrack.ref}`);
      const src = fileRef.getDownloadURL().then(res => {
        that.player.src = res;
      });
    }
  }

  handleUploadedFile = audio => {
    const audioFiles = [
      ...this.props.audioFiles,
      {
        id: audio.name + +new Date(),
        name: audio.name,
        url: audio.downloadURL,
        ref: audio.ref
      }
    ];

    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    const itemRef = db
      .collection("items")
      .doc(this.props.id)
      .set(
        {
          audioFiles
        },
        { merge: true }
      );
  };

  deleteUploadedFile = file => {
    const storageRef = firebase.storage().ref();
    var fileRef = storageRef.child(`${file.ref}`);
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });

    const audioFiles = this.props.audioFiles.filter(
      audio => audio.id !== file.id
    );
    const itemRef = db
      .collection("items")
      .doc(this.props.id)
      .set(
        {
          audioFiles
        },
        { merge: true }
      );

    // Delete the file
    fileRef
      .delete()
      .then(function() {
        // File deleted successfully
        console.log("gone!");
      })
      .catch(function(error) {
        // Uh-oh, an error occurred!
      });
  };

  handleAudio = action => {
    const player = this.player;
    switch (action) {
      case "play":
        player.play();
        this.setState({
          isPlaying: true,
          isPaused: false,
          isStopped: false
        });
        break;
      case "pause":
        player.pause();
        this.setState({ isPlaying: false, isPaused: true, isStopped: false });
        break;
      case "stop":
        if (player) {
          player.pause();
          player.currentTime = 0;
        }
        this.setState({
          isPlaying: false,
          isPaused: false,
          isStopped: true,
          selectedTrack: ""
        });
        break;
    }
  };

  render() {
    const { audioFiles = [] } = this.props;
    const { selectedTrack, isPlaying, isPaused, isStopped } = this.state;
    return (
      <div
        {...css({
          float: "left",
          width: "100%",
          height: "100%",
          overflowY: "scroll"
        })}
      >
        {selectedTrack && (
          <div
            {...css({
              float: "left",
              width: "100%",
              height: 48,
              borderRadius: 5,
              border: "1px solid #F7F7F7",
              boxSizing: "border-box",
              fontSize: 18,
              paddingLeft: 18,
              paddingRight: 18,
              paddingTop: 15,
              marginBottom: 24
            })}
          >
            {isPlaying ? (
              <i
                onClick={() => this.handleAudio("pause")}
                className="fas fa-pause"
                {...css({ float: "left", marginRight: 18 })}
              />
            ) : (
              <i
                onClick={() => this.handleAudio("play")}
                className="fas fa-play"
                {...css({ float: "left", marginRight: 18 })}
              />
            )}

            {isPlaying && (
              <i
                className="fas fa-stop"
                {...css({ float: "left" })}
                onClick={() => this.handleAudio("stop")}
              />
            )}
            {selectedTrack && (
              <div
                {...css(
                  {
                    float: "left",
                    width: isPlaying
                      ? "calc(100% - 100px)"
                      : "calc(100% - 85px)",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    marginLeft: 15
                  },
                  $(" span", {
                    display: "inline-block",
                    animation: `${marquee} 20s linear infinite`
                  })
                )}
              >
                <span>{selectedTrack.name}</span>
              </div>
            )}
            <i
              className="fas fa-trash-alt"
              {...css({ float: "right" })}
              onClick={() =>
                selectedTrack && this.deleteUploadedFile(selectedTrack)
              }
            />
          </div>
        )}
        <ul {...css({ margin: 0, padding: 0, listStyle: "none" })}>
          {audioFiles.map(audio => (
            <Audio
              key={audio.id}
              name={audio.name}
              selectAudio={() => this.setState({ selectedTrack: audio })}
            />
          ))}
        </ul>
        <Upload
          accept=".mp3"
          handleUploadedFile={file => this.handleUploadedFile(file)}
        />
        {selectedTrack && (
          <audio preload="auto" ref={ref => (this.player = ref)} />
        )}
      </div>
    );
  }
}

export default ContentAudio;

class Audio extends React.Component {
  render() {
    const { name, selectAudio } = this.props;
    return (
      <li {...css(listItem)} onClick={selectAudio}>
        {name}
      </li>
    );
  }
}

const marquee = css.keyframes({
  "0%": { transform: "translate(-100%, 0)" },
  "100%": { transform: "translate(50%, 0)" }
});
