import React from "react";
import { css, select as $ } from "glamor";
import { CSSTransition } from "react-transition-group";

import Title from "./components/Title";

import firebase from "./Firestore";

import { colors, listItem } from "./styles";
import { getLink } from "./helpers";

class Public extends React.Component {
  state = {
    title: "",
    text: "",
    color: "",
    tags: [],
    images: [],
    audioFiles: [],
    links: [],
    files: []
  };
  componentDidMount() {
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    const { id } = this.props;
    var docRef = db.collection("items").doc(id);
    const that = this;
    docRef
      .get()
      .then(function(doc) {
        if (doc.exists) {
          const data = doc.data();
          const {
            title,
            text,
            color,
            tags,
            images,
            audioFiles,
            links,
            files
          } = data;
          that.setState({
            title,
            text,
            color,
            tags,
            images,
            audioFiles,
            links,
            files
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
  }

  render() {
    const {
      title,
      text,
      images = [],
      audioFiles = [],
      links = [],
      files = [],
      color,
      tags
    } = this.state;

    const imageList = images.map(image => {
      return (
        <li key={image.id} {...css({ width: 75, height: 75, float: "left" })}>
          <img
            src={image.url}
            {...css({ maxWidth: "100%", maxHeight: "100%" })}
          />
        </li>
      );
    });
    const audioList = audioFiles.map(audio => {
      return (
        <li key={audio.id} {...css(listItem)}>
          <a href={audio.url} target="_blank">
            {audio.name}
          </a>
        </li>
      );
    });
    const linkList = links.map(link => {
      const url = getLink(link.url);
      return (
        <li key={link.id} {...css(listItem)}>
          <a href={url} target="_blank">
            {link.url}
          </a>
        </li>
      );
    });
    const fileList = files.map(file => {
      return (
        <li key={file.id} {...css(listItem)}>
          <a href={file.url} target="_blank">
            {file.name}
          </a>
        </li>
      );
    });

    return (
      <div>
        <div
          {...css({
            float: "left",
            width: "100%",
            padding: 24,
            paddingBottom: 72,
            boxSizing: "border-box"
          })}
        >
          <Title title={this.state.title} />

          <div
            {...css(
              {
                float: "left",
                width: "100%",
                marginTop: this.state.scrolled ? 72 : 24,
                border: 0,
                fontFamily: "Rubik",
                fontSize: 24,
                lineHeight: "120%",
                color: colors.dark,
                overflow: "hidden"
              },
              $(" h2", {
                fontSize: "1em"
              })
            )}
          >
            <div {...css({ float: "left", width: "100%" })}>{text}</div>
            <div {...css({ float: "left", width: "100%" })}>
              <h2>Images</h2>
              <ul {...css({ margin: 0, padding: 0, listStyle: "none" })}>
                {imageList}
              </ul>
            </div>
            <div {...css({ float: "left", width: "100%" })}>
              <h2>Audio</h2>
              <ul {...css({ margin: 0, padding: 0 })}>{audioList}</ul>
            </div>
            <div {...css({ float: "left", width: "100%" })}>
              <h2>Links</h2>
              <ul {...css({ margin: 0, padding: 0 })}>{linkList}</ul>
            </div>
            <div {...css({ float: "left", width: "100%" })}>
              <h2>Files</h2>
              <ul {...css({ margin: 0, padding: 0 })}>{fileList}</ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Public;
