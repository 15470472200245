export const colors = {
  dark: "#2A2A2A",
  light: "#EEEEEE",
  focus: "#26EFBF",
  hover: "#F2F2F2",
  grey: "#BABABA",
  warning: "#FF0000"
};

export const float = {
  float: "left",
  width: "100%"
};

export const title = {
  fontFamily: "Shrikhand",
  fontSize: 40,
  color: colors.dark,
  margin: 0
};

export const itemHeader = {
  fontSize: 20,
  color: colors.dark,
  margin: 0,
  padding: 0
};

export const listItem = {
  float: "left",
  width: "100%",
  lineHeight: "36px",
  background: "#F7F7F7",
  borderRadius: 3,
  paddingLeft: 12,
  paddingRight: 12,
  boxSizing: "border-box",
  marginBottom: 12,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
};
