import React from "react";
import { css, select as $ } from "glamor";
import { DropTarget, connectDropTarget } from "react-dnd";
import { types } from "../dropTypes";

import Tag from "./Tag";
import Color from "./Color";

import { colors, float, itemHeader } from "../styles";

const itemTarget = {
  drop(props, monitor) {
    const item = monitor.getItem();
    switch (item.type) {
      case "color":
        props.setColor(item);
        break;
      case "tag":
        props.handleTags(item);
        break;
      case "priority":
        props.setPriority(item);
        break;
      default:
        break;
    }
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

class Item extends React.Component {
  render() {
    const {
      id,
      title,
      color,
      tags = [],
      priority,
      selected,
      selectItem,
      connectDropTarget,
      isOver
    } = this.props;
    const tagList = tags.map(tag => {
      return <Tag key={tag.id} id={tag.id} name={tag.name} />;
    });
    return connectDropTarget(
      <li
        {...css(
          float,
          {
            borderRadius: 5,
            padding: 12.5,
            boxSizing: "border-box",
            marginBottom: 25,
            background: selected && colors.focus,
            boxShadow: selected && "0px 0px 10px 0px rgba(0,0,0,0.25)",
            opacity: isOver ? 0.5 : 1
          },
          $(":hover", {
            background: selected ? colors.focus : colors.hover,
            cursor: "pointer"
          })
        )}
        onClick={() => selectItem(id)}
      >
        <div
          {...css({
            float: "left",
            width: 100,
            height: 100,
            background: "white",
            borderRadius: 5
          })}
        />
        <div
          {...css({
            float: "left",
            width: "calc(100% - 100px)",
            paddingLeft: 12.5,
            boxSizing: "border-box"
          })}
        >
          <h1 {...css(itemHeader)}>{title}</h1>
          <Color is={color} />
          <ul>{tagList}</ul>
        </div>
      </li>
    );
  }
}

export default DropTarget(types, itemTarget, collect)(Item);
