import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

var config = {
  apiKey: "AIzaSyDdltS8pJfDzqr5CkeMGIiwLujFQFYROOk",
  authDomain: "rumaa-79309.firebaseapp.com",
  databaseURL: "https://rumaa-79309.firebaseio.com",
  projectId: "rumaa-79309",
  storageBucket: "rumaa-79309.appspot.com",
  messagingSenderId: "264704618090"
};
firebase.initializeApp(config);

export default firebase;
