import React from "react";
import { css } from "glamor";
import firebase from "../Firestore";

import { colors } from "../styles";

import Upload from "../components/Upload";

import banner from "../assets/img/banner.jpg";

class ContentImage extends React.Component {
  handleUploadedFile = image => {
    const images = [
      ...this.props.images,
      {
        id: image.name + +new Date(),
        name: image.name,
        url: image.downloadURL,
        ref: image.ref
      }
    ];
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    const itemRef = db
      .collection("items")
      .doc(this.props.id)
      .set(
        {
          images
        },
        { merge: true }
      );
  };
  render() {
    const { images } = this.props;
    return (
      <div
        {...css({
          float: "left",
          width: "100%",
          height: 80,
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr"
        })}
      >
        {images.map(image => (
          <Image key={image.id} url={image.url} />
        ))}
        <Upload
          accept=".jpg, .jpeg, .png"
          handleUploadedFile={file => this.handleUploadedFile(file)}
        />
      </div>
    );
  }
}

export default ContentImage;

class Image extends React.Component {
  state = {
    full: false
  };
  render() {
    const fullSize = this.state.full && {
      position: "fixed",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      zIndex: 2
    };
    return (
      <div {...css(fullSize)}>
        <img
          {...css({ objectFit: "fill", maxWidth: !this.state.full && "100%" })}
          src={this.props.url}
          onClick={() => this.setState({ full: true })}
        />
        {this.state.full && (
          <i
            className="fas fa-chevron-down"
            {...css({
              position: "absolute",
              color: colors.dark,
              fontSize: 24,
              right: 24,
              top: 24
            })}
            onClick={() => this.setState({ full: false })}
          />
        )}
      </div>
    );
  }
}
