import React from "react";
import firebase from "../Firestore";
import { css } from "glamor";
import { colors } from "../styles";
import Dropzone from "react-dropzone";

class Upload extends React.Component {
  state = {
    active: false,
    files: [],
    progress: 0,
    uploading: false,
    link: false,
    linkInput: ""
  };

  handleFiles = (selectedFiles, callback) => {
    this.setState({ uploading: true });
    const that = this;
    const file = selectedFiles[0];
    const storageRef = firebase.storage().ref();
    // Create the file metadata
    var metadata = {
      contentType: "image/jpeg"
    };

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef
      .child(`user/${that.props.uid}/images/` + file.name + "-" + +new Date())
      .put(file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function(snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        that.setState({ progress });
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      function(error) {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;

          case "storage/canceled":
            // User canceled the upload
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      function() {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          const ref = uploadTask.snapshot.ref.fullPath;
          callback && callback();
          that.setState({ uploading: false, active: false });
          that.props.handleUploadedFile({ name: file.name, downloadURL, ref });
        });
      }
    );
  };

  render() {
    const { handleLink, handleUploadedFile } = this.props;
    return (
      <div>
        <div
          {...css(
            getStyle(this.state.uploading, this.state.active, this.state.link)
          )}
          onClick={() =>
            !this.state.link &&
            this.setState(prevState => ({ active: !prevState.active }))
          }
        >
          {this.state.uploading ? (
            <div
              {...css({
                float: "left",
                height: "100%",
                width: this.state.progress + "%",
                minWidth: "24%",
                background: colors.focus,
                borderRadius: 3
              })}
            >
              {this.state.progress}%
            </div>
          ) : this.state.link ? (
            <div>
              <input
                {...css({
                  float: "left",
                  width: "calc(100% - 72px)",
                  border: 0,
                  lineHeight: "58px",
                  borderRadius: 3,
                  paddingLeft: 24,
                  boxSizing: "border-box",
                  fontSize: 24
                })}
                value={this.state.linkInput}
                placeholder="Enter link..."
                onChange={e => this.setState({ linkInput: e.target.value })}
              />{" "}
              <i
                {...css({
                  float: "left",
                  color: "#474747",
                  lineHeight: "60px",
                  marginLeft: 24
                })}
                className={
                  this.state.linkInput ? "fas fa-plus" : "fas fa-times"
                }
                onClick={
                  this.state.linkInput
                    ? () =>
                        handleLink(this.state.linkInput, () =>
                          this.setState({
                            active: false,
                            link: false,
                            linkInput: ""
                          })
                        )
                    : () => this.setState({ link: false })
                }
              />
            </div>
          ) : (
            <i className={this.state.active ? "fas fa-times" : "fas fa-plus"} />
          )}
        </div>
        {this.state.uploading ||
          (this.state.link && (
            <div
              {...css({
                position: "fixed",
                background: "rgba(0,0,0,0.1)",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%"
              })}
            />
          ))}
        {this.state.active && !this.state.uploading && (
          <div>
            {handleUploadedFile && (
              <div
                {...css({
                  position: "absolute",
                  right: 96,
                  bottom: 72,
                  background: "white",
                  fontSize: 13,
                  padding: "5px 9px",
                  borderRadius: 3,
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)"
                })}
              >
                <input
                  id="file"
                  type="file"
                  name="file"
                  accept={this.props.accept}
                  multiple
                  {...css({
                    width: 0.1,
                    height: 0.1,
                    opacity: 0
                  })}
                  onChange={e => this.handleFiles(e.target.files)}
                />
                <label htmlFor="file">Upload</label>
              </div>
            )}
            {handleLink && (
              <div
                {...css({
                  position: "absolute",
                  right: 96,
                  bottom: 103,
                  background: "white",
                  fontSize: 13,
                  padding: "5px 9px",
                  borderRadius: 3,
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)"
                })}
                onClick={() => this.setState({ link: true })}
              >
                Link
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Upload;

function getStyle(uploading, active, link) {
  let right;
  let left;
  let marginLeft;
  let marginRight;
  let width;
  let borderRadius;
  let background;
  if (link) {
    left = 0;
    marginLeft = 24;
    marginRight = 24;
    width = "calc(100% - 48px)";
    borderRadius = 3;
    background = "white";
  } else if (uploading) {
    left = 0;
    marginLeft = 24;
    marginRight = 24;
    width = "calc(100% - 48px)";
    borderRadius = 3;
    background = "white";
  } else {
    right = 24;
    left = "auto";
    marginLeft = 0;
    marginLeft = 0;
    width = 60;
    borderRadius = 60;
    background = active ? "#474747" : colors.focus;
  }
  return {
    position: "absolute",
    left,
    right,
    marginLeft,
    marginRight,
    bottom: 72,
    width,
    height: 60,
    borderRadius,
    background,
    textAlign: "center",
    lineHeight: "60px",
    color: "white",
    fontSize: 30,
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)",
    zIndex: 1,
    transition: "all 0.5s"
  };
}
