import React from "react";
import { css } from "glamor";
import {
  DragSource,
  beginDrag,
  connectDragSource,
  isDragging
} from "react-dnd";
import { types } from "../dropTypes";

import { colors } from "../styles";

function Tag({ connectDragSource, isDragging, name }) {
  return connectDragSource(
    <div
      {...css({
        float: "left",
        fontSize: 12,
        padding: "5px 7.5px",
        background: "white",
        color: colors.dark,
        borderRadius: 5,
        cursor: "pointer",
        opacity: isDragging && 0.5
      })}
    >
      {name}
    </div>
  );
}

const tagSource = {
  beginDrag(props) {
    return { type: "tag", id: props.id, name: props.name };
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

export default DragSource(types[1], tagSource, collect)(Tag);
