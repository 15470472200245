import React from "react";
import { css, select as $ } from "glamor";
import matchSorter from "match-sorter";

import Input from "../components/Input";

import { colors } from "../styles";

class MobileList extends React.Component {
  state = {
    input: ""
  }
  render() {
    const { items = [], selected, selectItem } = this.props;
    const filteredItems = matchSorter(items, this.state.input, {
      keys: ["title"]
    });
    const list = filteredItems.map(item => {
      return (
        <li
          key={item.id}
          {...css({
            float: "left",
            width: "100%",
            marginBottom: 24,
            fontSize: 24,
            fontWeight: 900,
            cursor: "pointer"
          })}
          onClick={() => selectItem(item.id)}
        >
          {item.title}
        </li>
      );
    });
    return (
      <div
        {...css({
          float: "left",
          width: "100%",
          padding: 24,
          boxSizing: "border-box"
        })}
      >
        <Input
          placeholder="Filter..."
          customStyle={$("::placeholder", { color: colors.grey })}
          value={this.state.input}
          handleChange={e => this.setState({input: e.target.value})}
        />
        <ul
          {...css({
            float: "left",
            width: "100%",
            margin: 0,
            marginTop: 24,
            padding: 0,
            listStyle: "none"
          })}
        >
          {list}
        </ul>
      </div>
    );
  }
}

export default MobileList;
