import React from "react";
import { css, select as $ } from "glamor";

import { colors } from "../styles";

function Button({
  name,
  type,
  size,
  float = "left",
  action,
  disabled,
  customStyle
}) {
  let background;
  let color;
  switch (type) {
    case "neutral":
      background = colors.dark;
      color = "white";
      break;
    case "finish":
      background = colors.focus;
      color = "white";
      break;
    default:
      break;
  }
  let fontSize;
  let height;
  let lineHeight;
  let paddingLeft;
  let paddingRight;
  switch (size) {
    case "small":
      fontSize = 14;
      height = 36;
      lineHeight = "36px";
      break;
    case "large":
      fontSize = 18;
      height = 42;
      lineHeight = "42px";
      paddingLeft = 12;
      paddingRight = 12;
      break;
    default:
      fontSize = 16;
      height = 36;
      lineHeight = "36px";
      break;
  }
  return (
    <button
      {...css(
        {
          float,
          lineHeight,
          height,
          background,
          color,
          borderRadius: 5,
          cursor: "pointer",
          border: 0,
          fontSize,
          paddingLeft,
          paddingRight
        },
        $(":disabled", {
          opacity: 0.2
        }),
        customStyle
      )}
      disabled={disabled}
      onClick={action}
    >
      {name}
    </button>
  );
}

export default Button;
