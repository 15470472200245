import React from "react";
import firebase from "./Firestore";
import { css, select as $ } from "glamor";

import Button from "./components/Button";
import Title from "./components/Title";

import { colors, float, title } from "./styles";

class Editor extends React.Component {
  state = {
    enlarged: false,
    title: "",
    text: ""
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      const { title = "", text = "" } = this.props;
      this.setState({ title, text });
    }
  }

  addItem = () => {
    const { title, text } = this.state;
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    const itemRef = db.collection("items").add({
      title,
      text,
      status: "PUBLISHED",
      tags: [],
      color: "",
      priority: "",
      uid: this.props.uid
    });
    this.setState({ title: "", text: "" });
  };

  updateItem = id => {
    const { title, text } = this.state;
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    const itemRef = db
      .collection("items")
      .doc(id)
      .set(
        {
          title,
          text
        },
        { merge: true }
      );
  };

  deleteItem = id => {
    const { title, text } = this.state;
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    db.collection("items")
      .doc(id)
      .delete()
      .then(function() {
        console.log("Document successfully deleted!");
      })
      .catch(function(error) {
        console.error("Error removing document: ", error);
      });
  };

  checkForChanges = () => {
    if (this.props.title !== this.state.title) {
      return false;
    }
    if (this.props.text !== this.state.text) {
      return false;
    }
    return true;
  };

  render() {
    const { enlarged, handleEnlarge, height } = this.props;
    const invalid = !this.state.title;
    return (
      <div
        {...css(
          {
            float: "left",
            width: "35%",
            height,
            overflowY: "scroll",
            overflowX: "hidden",
            transition: "width 0.5s"
          },
          $("::-webkit-scrollbar", {
            display: "none"
          })
        )}
      >
        {this.props.id && (
          <i
            {...css(
              {
                position: "absolute",
                right: 20,
                top: 20,
                fontSize: 20,
                zIndex: 1,
                color: colors.grey,
                cursor: "pointer",
                transition: "all 0.2s"
              },
              $(":hover", {
                color: colors.dark,
                transform: "scale(1.2)"
              })
            )}
            onClick={handleEnlarge}
            className="fas fa-expand-arrows-alt"
          />
        )}
        <div
          {...css({
            position: "absolute",
            width: enlarged ? "70%" : "35%",
            boxSizing: "border-box",
            background: "white",
            padding: 25,
            transition: "width 0.9s"
          })}
        >
          <div {...css({ float: "left", width: enlarged ? "70%" : "100%" })}>
            <div
              {...css({
                float: "left",
                width: this.props.id ? "calc(100% - 20px)" : "100%"
              })}
            >
              <Title
                title={this.state.title}
                placeholder="Enter a title..."
                handleChange={e => this.setState({ title: e.target.value })}
              />
            </div>
            <div
              {...css(float, {
                fontSize: 14,
                lineHeight: "20px",
                marginTop: 12.5,
                marginBottom: 25
              })}
            >
              <i className="fas fa-link" /> Public link
            </div>
            <div {...css(float, { marginBottom: 25 })}>
              <textarea
                {...css(
                  {
                    float: "left",
                    width: "100%",
                    border: 0,
                    fontSize: 18,
                    color: colors.dark,
                    margin: 0,
                    lineHeight: "125%",
                    height: 400,
                    resize: "none",
                    overflow: "hidden"
                  },
                  $(":focus", {
                    outline: "none"
                  })
                )}
                value={this.state.text}
                onChange={e => this.setState({ text: e.target.value })}
                placeholder="Enter some text..."
              />
            </div>
            {this.props.id ? (
              <div {...css(float)}>
                <Button
                  name="Update"
                  type="neutral"
                  action={() => this.updateItem(this.props.id)}
                  disabled={this.checkForChanges()}
                />{" "}
                <i
                  {...css({
                    marginLeft: 25,
                    fontSize: 24,
                    lineHeight: "34px",
                    cursor: "pointer"
                  })}
                  className="fas fa-trash-alt"
                  onClick={() => this.deleteItem(this.props.id)}
                />
                <Button name="Finish" type="finish" float="right" />
              </div>
            ) : (
              <div {...css(float)}>
                <Button
                  name="Create"
                  type="neutral"
                  action={this.addItem}
                  disabled={invalid}
                />
              </div>
            )}
          </div>
          {enlarged && (
            <div {...css({ float: "left" })}>
              <h2>meta</h2>
              <ul>
                <li>Created</li>
                <li>Tags</li>
                <li>color</li>
              </ul>
              <h2>Links</h2>
              <ul>
                <li>www.youtube.com</li>
                <li>www.twitter.com</li>
                <li>ADD a link</li>
              </ul>
              <h2>Media</h2>
              <ul>
                <li>Image</li>
                <li>Video</li>
                <li>MP3</li>
              </ul>
              <div>Drop MEDIA</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Editor;

const bounce = css.keyframes({
  "0%": { transform: "scale(0.8)" },
  "60%": { transform: "scale(1.2)" },
  "100%": { transform: "scale(1)" }
});
