import React from "react";
import { css, select as $, media } from "glamor";
import VisibilitySensor from "react-visibility-sensor";

import { colors, float, title, itemHeader } from "./styles";
import background from "./assets/img/banner.jpg";

import Title from "./components/Title";
import Button from "./components/Button";

class Desktop extends React.Component {
  state = {
    settingsHover: false
  };
  render() {
    const { height } = this.props;
    const list = this.props.items.map(item => {
      return (
        <li
          key={item.id}
          {...css(
            {
              float: "left",
              width: "100%",
              boxSizing: "border-box",
              fontSize: 24,
              padding: 24,
              borderRadius: 5,
              background: item.active && "#474747",
              color: item.active ? "white" : "#474747",
              marginBottom: 12,
              fontWeight: 500
            },
            $(":hover", {
              background: !item.active && colors.focus,
              color: !item.active && "white",
              cursor: "pointer"
            })
          )}
        >
          {item.title}
        </li>
      );
    });
    return (
      <div
        {...css(
          {
            display: "grid",
            gridTemplateColumns:
              "72px minmax(720px, 1.5fr) minmax(480px, 1fr) 0.75fr",
            boxSizing: "border-box",
            fontFamily: "Rubik",
            height
          },
          media("(max-width: 1368px)", {
            gridTemplateColumns: "72px minmax(720px, 1.5fr) minmax(480px, 1fr)"
          })
        )}
      >
        <div {...css({ background: colors.dark })}>
          <div {...css(iconStyle)}>
            <i className="fas fa-font" />
          </div>
          <div {...css(iconStyle)}>
            <i className="far fa-image" />
          </div>
          <div {...css(iconStyle)}>
            <i className="fas fa-music" />
          </div>
          <div {...css(iconStyle)}>
            <i className="fas fa-link" />
          </div>
          <div {...css(iconStyle, { color: "white" })}>
            <i className="fas fa-file" />
          </div>
          <div
            {...css(iconStyle, {
              position: "absolute",
              width: 72,
              bottom: 48,
              height: 60,
              lineHeight: "60px"
            })}
          >
            <i className="fas fa-user" />
          </div>
        </div>
        <div
          {...css({
            position: "relative"
          })}
        >
          <div
            {...css(
              {
                float: "left",
                width: "100%",
                padding: "24px 48px 48px 48px",
                height,
                boxSizing: "border-box",
                overflowY: "scroll",
                overflowX: "hidden"
              },
              $("::-webkit-scrollbar", {
                display: "none"
              })
            )}
          >
            <Title
              title={this.props.title}
              placeholder="Enter a title..."
              handleChange={e => this.setState({ title: e.target.value })}
              getHeight={titleHeight => this.setState({ titleHeight })}
            />
            <div
              {...css({
                float: "left",
                width: "100%"
              })}
            >
              EDITOR
            </div>
          </div>
          {this.state.settingsHover ? (
            <div
              {...css({
                position: "absolute",
                width: "calc(100% - 96px)",
                marginLeft: 48,
                height: 60,
                background: colors.light,
                borderRadius: 5,
                bottom: 48,
                boxSizing: "border-box",
                paddingLeft: 24,
                paddingRight: 24
              })}
              onMouseLeave={() => this.setState({ settingsHover: false })}
            >
              <div
                {...css({
                  float: "left",
                  fontSize: 24,
                  lineHeight: "60px",
                  color: "#4D4D4D",
                  marginRight: 24
                })}
              >
                <i className="fas fa-trash-alt" />
              </div>
              <div
                {...css({
                  float: "left",
                  fontSize: 24,
                  lineHeight: "60px",
                  color: "#4D4D4D"
                })}
              >
                <i className="fas fa-external-link-alt" />
              </div>
              <div {...css({ float: "right" })}>
                <div
                  {...css({
                    float: "left",
                    fontSize: 24,
                    lineHeight: "62px",
                    color: "#4D4D4D",
                    marginRight: 24
                  })}
                >
                  <i className="fas fa-hashtag" />
                </div>
                <div
                  {...css({
                    float: "left",
                    width: 24,
                    height: 24,
                    background: "#4D4D4D",
                    borderRadius: 24,
                    marginTop: 18,
                    marginRight: 24
                  })}
                />
                <div {...css({ float: "left", marginTop: 12 })}>
                  <Button name="Update" type="finish" />
                </div>
              </div>
            </div>
          ) : (
            <div
              {...css(
                {
                  position: "absolute",
                  bottom: 48,
                  right: 48,
                  width: 60,
                  height: 60,
                  background: colors.light,
                  borderRadius: 60,
                  fontSize: 32,
                  textAlign: "center",
                  lineHeight: "60px",
                  color: "#474747",
                  cursor: "pointer",
                  transition: "all 0.6s"
                },
                $(":hover", {
                  position: "absolute",
                  width: "calc(100% - 96px)",
                  marginLeft: 48,
                  height: 60,
                  background: colors.light,
                  borderRadius: 5,
                  bottom: 48,
                  boxSizing: "border-box",
                  paddingLeft: 24,
                  paddingRight: 24,
                  textAlign: "right"
                })
              )}
              onMouseOver={() => {
                const that = this;
                setTimeout(function() {
                  that.setState({ settingsHover: true });
                }, 600);
              }}
            >
              <i
                {...css(
                  { transition: "transform 1s" },
                  $(":hover", { transform: "rotate(360deg)" })
                )}
                className="fas fa-cog"
              />
            </div>
          )}
        </div>
        <div
          {...css({
            position: "relative",
            background: colors.light
          })}
        >
          <div
            {...css(
              {
                float: "left",
                width: "calc(100% - 48px)",
                marginLeft: 24,
                marginRight: 24,
                paddingBottom: 24,
                paddingTop: 24,
                background: colors.light,
                boxSizing: "border-box",
                height: height - 120,
                overflowY: "scroll"
              },
              $("::-webkit-scrollbar", {
                display: "none"
              })
            )}
          >
            <VisibilitySensor>
              {({ isVisible }) => (
                <div>
                  <input
                    {...css({
                      width: "100%",
                      height: 48,
                      lineHeight: "48px",
                      border: 0,
                      borderRadius: 5,
                      paddingLeft: 24,
                      paddingRight: 24,
                      boxSizing: "border-box",
                      fontSize: 18,
                      marginBottom: 24
                    })}
                    placeholder="Filter by title or tag..."
                  />
                  {!isVisible ? (
                    <div
                      {...css({
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        paddingLeft: 24,
                        paddingRight: 24,
                        paddingTop: 24,
                        boxSizing: "border-box"
                      })}
                    >
                      <input
                        {...css({
                          width: "100%",
                          height: 48,
                          lineHeight: "48px",
                          border: 0,
                          borderRadius: 5,
                          paddingLeft: 24,
                          paddingRight: 24,
                          boxSizing: "border-box",
                          fontSize: 18,
                          marginBottom: 24,
                          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)"
                        })}
                        placeholder="Filter by title or tag..."
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </VisibilitySensor>
            <ul
              {...css({
                float: "left",
                width: "100%",
                margin: 0,
                padding: 0,
                listStyle: "none",
                boxSizing: "border-box"
              })}
            >
              {list}
            </ul>
          </div>

          <div
            {...css({
              position: "absolute",
              float: "left",
              width: "100%",
              fontSize: 64,
              textAlign: "center",
              bottom: 48,
              boxSizing: "border-box",
              fontFamily: "Shrikhand",
              color: "#4D4D4D"
            })}
          >
            Ruma
          </div>
        </div>
        <div
          {...css(
            {
              backgroundImage: `url(${background})`,
              backgroundSize: "cover"
            },
            media("(max-width: 1368px)", {
              display: "none"
            })
          )}
        />
      </div>
    );
  }
}

const iconStyle = {
  ...css(
    {
      float: "left",
      width: "100%",
      height: 72,
      textAlign: "center",
      fontSize: 24,
      lineHeight: "72px",
      color: "#474747",
      cursor: "pointer"
    },
    $(":hover", {
      color: colors.focus
    })
  )
};

export default Desktop;
