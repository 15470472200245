import React from "react";
import { css, select as $ } from "glamor";

import { colors } from "../styles";

class Title extends React.Component {
  state = {
    textAreaHeight: 40
  };

  componentDidMount() {
    let textAreaHeight = this.ghost.clientHeight;
    this.setState({ textAreaHeight });
  }

  componentDidUpdate(nextProps, nextState) {
    if (
      nextProps.title !== this.props.title ||
      nextProps.size !== this.props.size
    ) {
      let textAreaHeight = this.ghost.clientHeight;
      this.setState({ textAreaHeight });
    }
  }
  render() {
    const { title, placeholder, handleChange, size } = this.props;
    return (
      <div
        {...css({
          float: "left",
          width: "100%",
          position: "relative",
          fontFamily: "Shrikhand",
          fontSize: size === "small" ? 24 : 40,
          color: colors.dark,
          margin: 0
        })}
      >
        {handleChange ? (
          <textarea
            {...css(
              {
                float: "left",
                width: "100%",
                border: 0,
                resize: "none",
                overflow: "hidden",
                height: this.state.textAreaHeight
              },
              $(":focus", {
                outline: "none"
              })
            )}
            value={title}
            onChange={handleChange}
            placeholder={placeholder}
          />
        ) : (
          title
        )}
        <div
          {...css({
            position: "absolute",
            display: "block",
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
            visibility: "hidden",
            fontFamily: "Shrikhand",
            fontSize: size === "small" ? 24 : 40,
            minHeight: size === "small" ? 34 : 50,
            width: "100%"
          })}
          ref={c => (this.ghost = c)}
        >
          {title}
        </div>
      </div>
    );
  }
}

export default Title;
