import React from "react";
import { css } from "glamor";

import { colors } from "../styles";

class Input extends React.Component {
  render() {
    const { placeholder, customStyle, value, handleChange } = this.props;
    return (
      <input
        {...css(
          {
            border: 0,
            borderBottom: "1px solid " + colors.dark,
            height: 48,
            fontSize: 24,
            fontWeight: 100,
            float: "left",
            width: "100%"
          },
          customStyle
        )}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />
    );
  }
}

export default Input;
