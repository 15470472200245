import React from "react";
import { css, select as $ } from "glamor";
import { navigate } from "@reach/router";

import Button from "../components/Button";

import { colors } from "../styles";

class ActionBar extends React.Component {
  state = {
    view: ""
  };
  render() {
    const { view } = this.state;
    const {
      closeEditor,
      selected,
      updateItem,
      deleteItem,
      disabled,
      tags,
      color,
      allTags,
      id
    } = this.props;

    let list;
    switch (view) {
      case "settings":
        list = (
          <ul
            {...css({
              padding: 0,
              margin: 0,
              marginTop: 6.75,
              listStyle: "none"
            })}
          >
            {[
              {
                id: 1,
                name: "Public link",
                action: () => navigate(`public/${id}`)
              },
              { id: 2, name: "Delete", action: deleteItem }
            ].map(item => {
              return (
                <li
                  key={item.id}
                  {...css({
                    fontSize: 20,
                    lineHeight: "30px",
                    color: item.name === "Delete" && colors.warning,
                    marginBottom: 6.75
                  })}
                  onClick={item.action}
                >
                  {item.name}
                </li>
              );
            })}
          </ul>
        );
        break;
      case "tags":
        list = (
          <Tags
            tags={this.props.tags}
            updateTags={this.props.updateTags}
            allTags={allTags}
          />
        );
        break;
      case "color":
        list = <ColorPicker updateColor={this.props.updateColor} />;
        break;
      default:
        break;
    }

    return (
      <div
        {...css({
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          height: view ? 148 : 48,
          background: "#FAFAFA",
          paddingLeft: 24,
          paddingRight: 24,
          boxSizing: "border-box",
          zIndex: 1,
          transition: "all .2s ease-in-out"
        })}
      >
        <div {...css({ float: "left", width: "100%" })}>
          {view ? (
            <div
              {...css({
                float: "left",
                textAlign: "left",
                color: colors.dark,
                fontSize: 24,
                lineHeight: "48px",
                marginRight: 12
              })}
              onClick={() => this.setState({ view: "" })}
            >
              <i className="fas fa-chevron-down" />
            </div>
          ) : (
            <div
              {...css({
                float: "left",
                textAlign: "left",
                color: colors.dark,
                fontSize: 24,
                lineHeight: "48px",
                marginRight: 12
              })}
              onClick={closeEditor}
            >
              <i className="fas fa-times" />
            </div>
          )}

          <div
            {...css({
              float: "right",
              cursor: "pointer",
              marginTop: 7,
              textAlign: "right"
            })}
          >
            <Button
              name={"Save"}
              size="small"
              type="finish"
              action={updateItem}
              disabled={disabled}
            />
          </div>
          <div
            {...css({
              float: "right",
              textAlign: "right",
              color: view === "settings" ? colors.dark : colors.grey,
              fontSize: 24,
              lineHeight: "48px",
              marginRight: 24,
              cursor: "pointer"
            })}
            onClick={() => this.setState({ view: "settings" })}
          >
            <i className="fas fa-ellipsis-v" />
          </div>
          <div {...css({ float: "right", marginRight: 12 })}>
            <div
              {...css({
                width: 24,
                height: 24,
                marginTop: 12,
                background: color
                  ? color
                  : view === "color"
                  ? colors.dark
                  : colors.grey,
                borderRadius: 32
              })}
              onClick={() => this.setState({ view: "color" })}
            />
          </div>
          <div
            {...css({
              float: "right",
              color: view === "tags" ? colors.dark : colors.grey,
              fontSize: 24,
              lineHeight: "48px",
              marginRight: 12
            })}
            onClick={() => this.setState({ view: "tags" })}
          >
            <i className="fas fa-hashtag" />
          </div>
        </div>
        <div {...css({ float: "left", width: "100%" })}>{list}</div>
      </div>
    );
  }
}
export default ActionBar;

function Tags({ tags, updateTags, allTags }) {
  const addTagsLength = 4 - tags.length;
  const tagsRight = [...tags];
  let count = 0;
  while (count !== addTagsLength) {
    tagsRight.push({ id: count, name: "" });
    count++;
  }
  const tagsLeft = tagsRight.splice(0, Math.ceil(tagsRight.length / 2));
  const tagsListLeft = tagsLeft.map(tag => {
    return (
      <Tag
        key={tag.id}
        id={tag.id}
        tag={tag.name}
        updateTags={updateTags}
        allTags={allTags}
      />
    );
  });

  const tagsListRight = tagsRight.map(tag => {
    return (
      <Tag
        key={tag.id}
        id={tag.id}
        tag={tag.name}
        updateTags={updateTags}
        allTags={allTags}
      />
    );
  });
  return (
    <div {...css({ float: "left", width: "100%", marginTop: 6.75 })}>
      <div
        {...css({
          float: "left",
          width: "calc(50% - 25px)",
          marginRight: 25
        })}
      >
        {tagsListLeft}
      </div>
      <div
        {...css({
          float: "left",
          width: "calc(50% - 25px)",
          marginLeft: 25
        })}
      >
        {tagsListRight}
      </div>
    </div>
  );
}

class Tag extends React.PureComponent {
  state = {
    addTag: false
  };
  render() {
    const { id, tag, updateTags, allTags } = this.props;
    return (
      <div
        {...css({
          fontSize: 20,
          lineHeight: "30px",
          marginBottom: 12.5
        })}
      >
        {tag ? (
          <div
            {...css({
              background: colors.focus,
              borderRadius: 5,
              paddingLeft: 6.25,
              paddingRight: 6.25,
              boxSizing: "border-box"
            })}
          >
            {tag}
            <i
              {...css({ float: "right", lineHeight: "30px" })}
              className="fas fa-times"
              onClick={() => updateTags({ id })}
            />
          </div>
        ) : (
          <div
            {...css({
              color: "#BABABA",
              paddingLeft: 6.25,
              paddingRight: 6.25,
              boxSizing: "border-box"
            })}
            onClick={() => this.setState({ addTag: true })}
          >
            Add tag
          </div>
        )}
        {this.state.addTag && (
          <AddTag
            closeModal={() => this.setState({ addTag: false })}
            updateTags={updateTags}
            allTags={allTags}
          />
        )}
      </div>
    );
  }
}

class AddTag extends React.Component {
  state = {
    input: ""
  };

  updateTag = idOrName => {
    this.props.updateTags(idOrName);
    this.props.closeModal();
  };

  render() {
    const allTags = this.props.allTags.tags;
    const mostUsed = this.props.allTags.mostUsed;
    const tags = mostUsed.map(tag => {
      return (
        <li key={tag.id}>
          <div
            {...css({
              background: colors.focus,
              borderRadius: 5,
              paddingLeft: 6.25,
              paddingRight: 6.25,
              boxSizing: "border-box",
              width: "max-content"
            })}
            onClick={() => this.updateTag(tag)}
          >
            {tag.name}
          </div>
        </li>
      );
    });
    return (
      <div
        {...css({
          position: "fixed",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          background: "white",
          padding: 24,
          boxSizing: "border-box"
        })}
      >
        <div {...css({ float: "left", width: "100%" })}>
          <div {...css({ fontSize: 24, marginTop: 24 })}>Most used tags...</div>
          <ul>{tags}</ul>
        </div>

        <input
          {...css({
            float: "left",
            width: this.state.input ? "calc(100% - 50px)" : "100%",
            border: 0,
            fontSize: 24,
            lineHeight: "36px",
            marginBottom: 12.5
          })}
          value={this.state.input}
          onChange={e => this.setState({ input: e.target.value })}
          placeholder="Create new tag..."
        />
        {this.state.input && (
          <div {...css({ float: "right" })}>
            <Button
              name={"Add"}
              size="small"
              type="finish"
              action={() => {
                this.updateTag({ name: this.state.input });
              }}
              disabled={false}
            />
          </div>
        )}
        <i
          className="fas fa-times"
          {...css({ position: "absolute", fontSize: 24, top: 24, right: 24 })}
          onClick={this.props.closeModal}
        />
      </div>
    );
  }
}

function ColorPicker({ updateColor }) {
  const colors = [
    "red",
    "bisque",
    "pink",
    "blueviolet",
    "coral",
    "purple",
    "orange",
    "fuchsia",
    "greenyellow",
    "lime",
    "maroon",
    "midnightblue",
    "orangered",
    "sienna",
    "slategray",
    "tomato"
  ].map(color => {
    return (
      <Color key={color} color={color} updateColor={() => updateColor(color)} />
    );
  });
  return (
    <div
      {...css({
        display: "grid",
        width: "100%",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 24px",
        marginTop: 12.5
      })}
    >
      {colors}
    </div>
  );
}

function Color({ color, updateColor }) {
  return (
    <div
      {...css(
        {
          background: color,
          width: 24,
          height: 24,
          borderRadius: 24,
          marginBottom: 12.5,
          transition: "all 0.2s ease-in-out"
        },
        $(":active", {
          transform: "scale(1.1, 1.1)"
        })
      )}
      onClick={updateColor}
    />
  );
}
