import React from "react";
import { css, select as $ } from "glamor";

import Item from "./components/Item";
import Trash from "./components/Trash";

class Items extends React.Component {
  render() {
    const { selected, selectItem, height, enlarged } = this.props;
    const items = this.props.items.map(item => {
      return (
        <Item
          id={item.id}
          key={item.id}
          title={item.title}
          color={item.color}
          setColor={color =>
            this.props.updateItem(item.id, { color }, this.props.items)
          }
          handleTags={tag => {
            this.props.updateItem(item.id, { tag }, this.props.items);
          }}
          setPriority={priority =>
            this.props.updateItem(item.id, { priority }, this.props.items)
          }
          tags={item.tags}
          priority={item.priority}
          selected={item.id === selected}
          selectItem={selectItem}
        />
      );
    });
    return (
      <div
        {...css(
          {
            float: "left",
            width: "35%",
            height,
            overflowY: "scroll",
            overflowX: "hidden",
            fontFamily: "Rubik",
            background: "#F7F7F7",
            padding: "25px 50px",
            boxSizing: "border-box"
          },
          $("::-webkit-scrollbar", {
            display: "none"
          })
        )}
      >
        <Trash />
        <ul {...css({ listStyle: "none", padding: 0, margin: 0 })}>{items}</ul>
      </div>
    );
  }
}
export default Items;
