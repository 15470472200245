import React from "react";
import { css, select as $ } from "glamor";
import { colors } from "../styles";

class ContentText extends React.Component {
  state = {
    focus: false,
    textAreaHeight: 30
  };

  componentDidMount() {
    let textAreaHeight = this.ghost.clientHeight;
    this.setState({ textAreaHeight });
  }

  componentDidUpdate(nextProps, nextState) {
    if (nextProps.value !== this.props.value) {
      let textAreaHeight = this.ghost.clientHeight;
      this.setState({ textAreaHeight });
    }
  }
  render() {
    const { value, handleChange, height } = this.props;
    const focused = this.state.focus && {
      position: "absolute",
      background: "white",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 1,
      padding: 24,
      paddingTop: 48,
      overflowY: "scroll",
      overflowX: "hidden",
      boxSizing: "border-box"
    };
    return (
      <div {...css({ position: "relative" }, focused)}>
        {this.state.focus && (
          <i
            className="fas fa-chevron-down"
            {...css({
              position: "absolute",
              top: 24,
              right: 24,
              fontSize: 24,
              cursor: "pointer"
            })}
            onClick={() => this.setState({ focus: false })}
          />
        )}
        <textarea
          {...css(
            {
              float: "left",
              width: "100%",
              border: 0,
              fontFamily: "Rubik",
              fontSize: 24,
              lineHeight: "120%",
              color: colors.dark,
              margin: 0,
              resize: "none",
              overflow: "hidden",
              height: this.state.textAreaHeight + 24
            },
            $(":focus", {
              outline: "none"
            })
          )}
          value={value}
          onChange={handleChange}
          onFocus={() => this.setState({ focus: true })}
          placeholder={"Enter some text"}
        />
        <div
          {...css({
            position: "absolute",
            display: "block",
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
            visibility: "hidden",
            fontFamily: "Rubik",
            lineHeight: "120%",
            fontSize: 24,
            minHeight: 24,
            width: "100%"
          })}
          ref={c => (this.ghost = c)}
        >
          {value}
        </div>
      </div>
    );
  }
}

export default ContentText;
