import React from "react";
import firebase from "./Firestore";
import App from "./App";
import { css, media } from "glamor";
import { colors } from "./styles";
import Button from "./components/Button";

var provider = new firebase.auth.GoogleAuthProvider();

class Auth extends React.Component {
  state = {
    uid: "",
    loading: true
  };
  componentDidMount() {
    const that = this;
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        console.log(user);
        const uid = user.uid;
        that.setState({ uid, loading: false });
      } else {
        console.log("not signed in");
        // No user is signed in.
        that.setState({ loading: false });
      }
    });
  }

  signIn = () => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function(result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // The signed-in user info.
        var user = result.user;
        console.log(user);
        // ...
      })
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
  };

  render() {
    if (this.state.loading) {
      return <div>loading...</div>
    } else if (this.state.uid) {
      return <App uid={this.state.uid} />;
    } else {
      return (
        <div
          {...css({
            float: "left",
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "#EEEEEE"
          })}
        >
          <div
            {...css(
              {
                float: "left",
                width: "100%",
                height: "100%"
              },
              media("(min-width: 801px)", {
                display: "none"
              })
            )}
          >
            <div
              {...css({
                float: "left",
                width: "100%",
                height: 245,
                background: "white",
                textAlign: "center",
                paddingTop: 60,
                boxSizing: "border-box"
              })}
            >
              <h1
                {...css({
                  fontFamily: "Shrikhand",
                  fontSize: 64,
                  padding: 0,
                  margin: 0,
                  color: "#4D4D4D",
                  fontWeight: 300,
                  marginBottom: 24
                })}
              >
                Ruma
              </h1>
              <div
                {...css({
                  margin: 0,
                  padding: 0,
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                  fontSize: 24,
                  paddingLeft: 24,
                  paddingRight: 24,
                  boxSizing: "border-box",
                  color: "#4D4D4D"
                })}
              >
                <i
                  className={"fas fa-font"}
                  {...css({ color: colors.focus })}
                />
                <i className={"far fa-image"} />
                <i className={"fas fa-music"} />
                <i className={"fas fa-link"} />
                <i className={"fas fa-file"} />
              </div>
            </div>
            <div
              {...css({
                float: "left",
                width: "100%",
                fontFamily: "Rubik",
                paddingTop: 36
              })}
            >
              <Button
                customStyle={{
                  marginLeft: "50%",
                  transform: "translateX(-50%)"
                }}
                type="finish"
                action={this.signIn}
                name="Sign in with Google"
                size="large"
              />
            </div>
          </div>
          <div
            {...css(
              media("(max-width: 800px)", {
                display: "none"
              })
            )}
          >
            DESKTOP
            <button onClick={this.signIn}>SIGN IN</button>
          </div>
        </div>
      );
    }
  }
}

export default Auth;
