import React from "react";
import { css } from "glamor";
import firebase from "./Firestore";

import MobileEditor from "./mobile/MobileEditor";
import MobileList from "./mobile/MobileList";

import { colors } from "./styles";

class Mobile extends React.Component {
  state = {
    view: "list"
  };

  addItem = selectItem => {
    const db = firebase.firestore();
    db.settings({
      timestampsInSnapshots: true
    });
    const itemRef = db.collection("items").add({
      title: "Untitled",
      text: "",
      status: "PUBLISHED",
      tags: [],
      color: "",
      uid: this.props.uid
    });

    itemRef
      .then(res => {
        console.log(res.id);
        selectItem(res.id);
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const { view } = this.state;
    const {
      uid,
      id,
      title,
      text,
      images = [],
      music = [],
      links = [],
      files = [],
      tags,
      color,
      items,
      selected,
      selectItem,
      height,
      allTags
    } = this.props;
    return (
      <div>
        {view === "list" && (
          <MobileHeader
            openEditor={() => {
              this.addItem(id => {
                selectItem(id);
                this.setState({ view: "editor" });
              });
            }}
          />
        )}
        {view === "list" ? (
          <MobileList
            selected={selected}
            selectItem={id => {
              this.setState({ view: "editor" });
              selectItem(id);
            }}
            items={items}
          />
        ) : (
          <MobileEditor
            uid={uid}
            id={id}
            title={title}
            text={text}
            images={images}
            audioFiles={music}
            links={links}
            files={files}
            tags={tags}
            color={color}
            closeEditor={() => this.setState({ view: "list" })}
            selected={selected}
            height={height}
            allTags={allTags}
          />
        )}
      </div>
    );
  }
}

const MobileHeader = ({ openEditor }) => {
  return (
    <div
      {...css({
        float: "left",
        width: "100%",
        height: 48,
        lineHeight: "48px",
        background: colors.dark,
        fontSize: 24,
        color: "white"
      })}
    >
      <div
        {...css({
          float: "left",
          width: 48,
          textAlign: "center",
          marginRight: 12
        })}
      >
        <i className="fas fa-bars" />
      </div>
      <div
        {...css({
          float: "left",
          color: colors.focus,
          fontFamily: "Shrikhand"
        })}
      >
        Ruma
      </div>
      <div
        {...css({
          float: "right",
          width: 48,
          textAlign: "center",
          cursor: "pointer"
        })}
        onClick={openEditor}
      >
        <i className="fas fa-plus" />
      </div>
    </div>
  );
};

export default Mobile;
