import React from "react";
import { Router } from "@reach/router";

import Public from "./Public";
import Auth from "./Auth";

class Route extends React.Component {
  render() {
    return (
      <Router>
        <Public path="/public/:id" />
        <Auth path="/" />
      </Router>
    );
  }
}

export default Route;
