import React from "react";
import { css, select as $ } from "glamor";

import { colors } from "../styles";

class TextArea extends React.Component {
  state = {
    textAreaHeight: 18
  };

  componentDidMount() {
    let textAreaHeight = this.ghost.clientHeight;
    this.setState({ textAreaHeight });
  }

  componentDidUpdate(nextProps, nextState) {
    if (nextProps.text !== this.props.text) {
      let textAreaHeight = this.ghost.clientHeight;
      this.setState({ textAreaHeight });
    }
  }
  render() {
    const { text, placeholder, handleChange, onFocus, onBlur } = this.props;
    return (
      <div {...css({ position: "relative" })}>
        <textarea
          {...css(
            {
              float: "left",
              width: "100%",
              border: 0,
              fontSize: 18,
              color: colors.dark,
              margin: 0,
              lineHeight: "125%",
              resize: "none",
              overflow: "hidden",
              height: this.state.textAreaHeight
            },
            $(":focus", {
              outline: "none"
            })
          )}
          value={text}
          onChange={handleChange}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <div
          {...css({
            position: "absolute",
            display: "block",
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
            visibility: "hidden",
            fontFamily: "Shrikhand",
            fontSize: 18,
            minHeight: 18,
            width: "100%"
          })}
          ref={c => (this.ghost = c)}
        >
          {text}
        </div>
      </div>
    );
  }
}

export default TextArea;
